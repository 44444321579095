<!--修改钱包提现密码-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20" v-if="type==2">
                <el-form-item :label="msg('旧提现密码')">
                    <el-input v-model.trim="oldPwd" type="password" show-password :placeholder="msg('输入旧提现密码')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('提现密码')">
                    <el-input v-model.trim="password" type="password" show-password :placeholder="msg('输入提现密码')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('确认密码')">
                    <el-input v-model.trim="rePassword" type="password" show-password :placeholder="msg('输入提现密码')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item>
                    <el-tag type="danger">{{msg("提示：请妥善保管提现密码，提现必须使用密码才能提现")}}</el-tag>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'companyUpdate',
    data(){
        return {
            oldPwd:null,
            password:null,
            rePassword:null,
        }
    },
    mounted(){
    },
    props:{
        id:Number,
        type: Number //1-设置，2-修改
    },
    methods:{
        init(){
            this.oldPwd = null;
            this.password = null;
            this.rePassword = null;
        },
        onSubmit(){
            if(!this.password){
                this.$message(this.msg("请输入提现密码"));
                return;
            }
            if(!this.rePassword){
                this.$message(this.msg("请输入确认密码"));
                return;
            }
            if(this.password != this.rePassword){
                this.$message(this.msg("两次输入的密码不一致，请重新输入"));
                return;
            }
            if(this.type == 2){
                if(!this.oldPwd){
                    this.$message(this.msg("请输入旧提现密码"));
                    return;
                }
            }

            this.$prompt("请输入系统登录密码", this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                inputType: 'password',
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.wallet.checkLoginPwd+"?pad="+encodeURIComponent(value))
                    .then((result) => {
                        if(result.status){
                            this.doUpdate();
                        }else{
                            this.$message(this.msg('登录密码不正确'));
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        doUpdate(){
            let param = {
                id:this.id,
                password:this.password,
                oldPwd:this.oldPwd
            }
            var _this = this;
            this.$axios.post(this.$kit.api.wallet.updateWalletPassword+"?type="+this.type, param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg("更新成功"));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg ||  _this.msg('更新失败'));
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-autocomplete-suggestion{
    width: auto!important;
    }
</style>