<!--我的钱包-->
<template>
    <div>
        <div class="ctrl-wrap" v-if="wallets.length>0">
            <h3>{{walletName?(walletName+'的钱包'):'我的钱包'}}</h3>
            <el-row v-for="(wallet,index) in wallets" :key="index">
                <el-col :xs="12" :sm="3">
                    <span class="label">{{msg('开户公司')}}：</span>
                    <span class="value">
                        <span style="color: #409EFF;font-size:17px;font-weight:bold;">{{wallet.companyName}}</span>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="3">
                    <span class="label">{{msg('钱包状态')}}：</span>
                    <span class="value">
                        <!-- <el-tag v-if="wallet.balance>=0">正常</el-tag>
                        <el-tag v-else type="danger">余额不足</el-tag> -->
                        <span v-if="wallet.balance <= 0" style="color: #F56C6C">{{"余额不足"}}</span>
                        <span v-else style="color: #67C23A">{{"正常"}}</span>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="3">
                    <span class="label">{{msg('账户余额')}}：</span>
                    <span class="value">
                        <span v-if="wallet.balance<=0" style="color: #F56C6C">{{wallet.balance}}</span>
                        <span v-else style="color: #67C23A">{{wallet.balance}}</span>
                        <el-link style="margin-left:2px;" icon="el-icon-refresh" @click="getWallet(1)" :underline="false"></el-link>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="3">
                    <span class="label">{{msg('信用额度')}}：</span>
                    <span class="value">
                        <span v-if="wallet.loan<=0" style="color: #F56C6C">{{wallet.loan}}</span>
                        <span v-else style="color: #67C23A">{{wallet.loan}}</span>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="2">
                    <span class="label">{{msg('积分')}}：</span>
                    <span class="value">
                        <span v-if="wallet.points<=0" style="color: #F56C6C"><el-link type="danger" @click="doOpenPoint()">{{wallet.points}}</el-link></span>
                        <span v-else style="color: #67C23A"><el-link type="success" @click="doOpenPoint()">{{wallet.points}}</el-link></span>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="2">
                    <span class="label">{{msg('优惠券')}}：</span>
                    <span class="value">
                        <span v-if="wallet.couponNum<=0" style="color: #F56C6C"><el-link type="danger" @click="doOpenCoupon()">{{wallet.couponNum}}</el-link></span>
                        <span v-else style="color: #67C23A"><el-link type="success" @click="doOpenCoupon()">{{wallet.couponNum}}</el-link></span>
                    </span>
                </el-col>
                <el-col :xs="12" :sm="2">
                    <el-link type="primary" :underline="false" style="font-size:15px;margin-top:-3px" @click="inFreight(wallet)">
                        <i style="margin-right:2px;margin-top:2px;" class="iconfont icon-tixian font15"></i>
                        {{msg('运费储值')}}
                    </el-link>
                </el-col>
                <el-col :xs="12" :sm="2">
                    <el-link type="success" :underline="false" style="font-size:15px;margin-top:-3px" @click="cashOut(wallet)">
                        <i style="margin-right:2px;margin-top:2px;" class="iconfont icon-tixian font15"></i>
                        {{msg('申请提现')}}
                    </el-link>
                </el-col>
                <el-col :xs="12" :sm="2">
                    <el-link type="danger" :underline="false" style="font-size:15px;margin-top:-3px" @click="editWalletPassword(wallet.id, wallet.password)">
                        <i style="margin-right:2px;margin-top:2px;" class="iconfont icon-tixian font15"></i>
                        {{msg('钱包安全')}}
                    </el-link>
                </el-col>
            </el-row>
        </div>

        <div class="ctrl-wrap" v-if="userId">
            <detail :user-id="userId" @refresh-wallet="getWallet(1)"></detail>
        </div>

        <el-dialog :title="dgTitle" :close-on-click-modal="false"  @opened="openedUpdatePwd"
            :visible.sync="dgUpdatePwdVisible" width="550px"
            :fullscreen="isMinScreen">
                <wallet-update-password :id="walletId" :type="dgType" ref="updatePwdRef" @close-dg="closeUpdatePwdDialog" />
        </el-dialog>

        <el-dialog
            :title="msg('申请提现')"
            :visible.sync="cashOutVisiable"
            :close-on-click-modal="false"
            width="30%">
            <el-form ref="form" :model="cashOutForm" label-width="80px">
                <el-form-item :label="msg('开户公司')">
                    <el-tag type="success">{{cashOutForm.companyName}}</el-tag>
                </el-form-item>
                <el-form-item :label="msg('提现金额')">
                    <el-input type="number" v-model="cashOutForm.amount" :placeholder="msg('请输入提现金额')"></el-input>
                </el-form-item>
                <el-form-item :label="msg('收款信息')">
                    <el-input type="textarea" v-model="cashOutForm.remark"></el-input>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="cashOutVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doCashOut()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :title="msg('运费储值--'+companyName1)"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            width="50%">
            <span>
                <el-tag style="margin-bottom:20px" type="danger">温馨提示：转账后请上传转账凭证给财务审核！</el-tag>
            </span>
            <span>
                <el-switch
                    style="display: block;margin-bottom:20px"
                    v-model="showPayImg"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="查看收款账号"
                    inactive-text="">
                </el-switch>
            </span>
            <span v-show="showPayImg">
                <el-image 
                    style="width: 200px; height: 200px"
                    :src="imgUrl+imgId"
                    :preview-src-list="[imgUrl+imgId]"
                    >
                </el-image>
            </span>
            <!-- <span>
                开户公司：<el-tag type="success">{{companyName1}}</el-tag>
            </span> -->
            <span>
                <el-tag style="margin-top:10px" type="info">请输入已转账金额：</el-tag>
                <el-input type="number" v-model="amount1" :placeholder="msg('请输入充值金额')"></el-input>
            </span>
            <span>
                <el-input style="margin-top:20px" v-model="remark1" :placeholder="msg('请输入转账流水号或相关备注')"></el-input>
            </span>
            <span>
                <el-upload
                    style="margin-top:15px;"
                    class="xm-upload"
                    :action="upload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :on-success="(res) => handUploadSuccess(res)"
                >
                    <el-button size="small" type="primary">{{msg('上传转账截图凭证')}}</el-button>
                    <div slot="tip" class="el-upload__tip">{{msg('只能上传jpg/png文件，且不超过5MB')}}</div>
                </el-upload>
                <el-image
                    v-if="this.attachmentId!=0"
                    style="width: 120px; height: 120px" :src="imgUrl+this.attachmentId"
                    :preview-src-list="[imgUrl+this.attachmentId]"
                    fit="contain">
                </el-image>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doFreight()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>


    </div>
</template>
<script>
import Detail from "./WalletDetail.vue"
import WalletUpdatePassword from "./WalletUpdatePassword.vue"
export default {
    name: 'Wallet',
    components: {
        Detail: Detail,
        WalletUpdatePassword:WalletUpdatePassword
    }, 
    mounted(){
        this.setParam();
    },
    data(){
        return {
            userId: null,
            prevUserId: null,
            wallet: null,
            wallets:[],
            walletName:null,
            dgTitle:null,
            walletId:null,
            dgUpdatePwdVisible:false,
            dgType:null,
            cashOutVisiable:false,
            cashOutForm: {
                companyName:null,
                amount: 0,
                remark: null,
            },

            upload: this.$kit.api.upload+"?type=stock/wallet&auth=1",
            imgUrl: this.$kit.api.imgView,
            imgId:0,
            dialogVisible:false,//储值弹窗
            amount1:0,
            remark1:null,
            attachmentId:0,
            companyName1:null,
            showPayImg:false,
        }
    },
    computed:{
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
    }, 
    methods: {
        setParam(){
            this.userId = this.$route.params.id*1;
            if(this.userId != this.prevUserId){
                this.pervUserId = this.userId;
            }
            this.getWallet();
        },
        getWallet(refresh){
            this.$axios.get(this.$kit.api.wallet.get+"?id="+this.userId)
                .then((result) => {
                    if(result.status){
                        if(result.data){
                            // result.data.balance = (result.data.balance/100.0).toFixed(2);
                            // if(result.data && refresh){
                            //     this.wallet = result.data;
                            //     this.wallet.balance = result.data.balance;
                            // }else{
                            //     this.wallet = result.data
                            // }
                            let data = result.data;
                            this.walletName = data[0].fullname;
                            this.wallets = data;
                            this.wallets.forEach(e=>{
                                if(e.loan){
                                    e.loan = (e.loan/100).toFixed(0);
                                }
                                if(e.balance){
                                    e.balance = (e.balance/100).toFixed(2);
                                }
                                if(!e.couponNum){
                                    e.couponNum = 0;
                                }
                            })
                        }
                    }
                });
        },
        doOpenPoint(){
            this.$router.push("/admin/point-detail/");
        },
        doOpenCoupon(){
            this.$router.push("/admin/coupon/");
        },
        editWalletPassword(id,password){
            //检查账户是否设置提现密码
            if(password){
                //如果已设置，则修改密码
                this.dgTitle = "修改提现密码";
                this.dgType = 2;
            }else{
                //如果未设置，则进行设置
                this.dgTitle = "提现密码设置";
                this.dgType = 1;
            }
            this.walletId = id;
            this.dgUpdatePwdVisible = true;
        },
        cashOut(wallet){//申请提现
            this.cashOutForm.amount = 0;
            this.cashOutForm.remark = null;
            this.cashOutForm.companyName = wallet.companyName;
            this.walletId = wallet.id;
            if(!wallet.password){
                this.$message(this.msg("请先点击钱包安全设置提现密码"));
                return;
            }
            this.cashOutVisiable=true;
        },
        inFreight(wallet){//运费储值
            this.amount1 = 0,
            this.remark1 = null,
            this.attachmentId = 0,
            this.companyName1 = wallet.companyName;
            this.imgId = wallet.imgId,
            this.walletId = wallet.id;
            this.dialogVisible = true;
        },
        doFreight(){//保存运费储值
            this.$axios.post(this.$kit.api.wallet.doFreight+'?amount='+(this.amount1*100).toFixed(0)+'&remark='+(this.remark1 || '')+'&attachmentId='+this.attachmentId+'&walletId='+this.walletId)
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg("保存成功"));
                        this.getWallet(1);
                        this.dialogVisible = false;
                    }else{
                        this.$message(result.msg || this.msg('保存失败'));
                    }
                });
        },
        openedUpdatePwd(){
            this.$nextTick(() => {
                this.$refs.updatePwdRef.init();
            });
        },
        closeUpdatePwdDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.getWallet();
            }
            this.dgUpdatePwdVisible = false;
        },
        doCashOut(){
            this.cashOutForm.amount = this.cashOutForm.amount*1;
            if(!this.cashOutForm.amount){
                this.$message(this.msg('请输入金额'));
                return;
            }

            if(this.cashOutForm.amount<=0){
                this.$message(this.msg('金额不能小于等于0'));
            }

            if(!this.cashOutForm.remark){
                this.$message(this.msg('请输入收款信息'));
            }

            this.$prompt("请输入提现密码", this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                inputType: 'password',
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.wallet.checkWalletPwd+"?wId="+this.walletId+"&pwd="+value)
                    .then((result) => {
                        if(result.status){
                            this.doCashOutSub();
                        }else{
                            this.$message(result.msg || this.msg('提现密码不正确'));
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        doCashOutSub(){
            let data = {
                id: this.walletId,
                amount: this.cashOutForm.amount * 100,
                remark: this.cashOutForm.remark
            }

            this.$axios.post(this.$kit.api.wallet.cashOut, data)
                .then((result) => {
                    
                    this.$message(result.msg);
                    if(result.status){
                        this.getWallet(1);
                        this.cashOutVisiable = false;
                    }
                });
        },
        handUploadSuccess(res){
            if(res.status){
                //保存图片ID
                this.attachmentId = res.data;
                this.$message("上传成功");
            }else{
                this.$message("上传失败");
            }
        },
    }

}
</script>
<style scoped lang="scss">
</style>